import React from "react";
import { BrowserRouter as Router, Route} from "react-router-dom";
import Predict from "./Predict";
import ImageAnalyze from "./ImageAnalyze";
import CaptureFrame from "./VideoAnalyze/CaptureFrame";
import FakePredict from "./FakePredict";
import Upload from "./Upload";
import "./App.css";



const App = () => {
  return(
           
           <Router>
            <Route exact path="/" component={ImageAnalyze} />
            <Route path="/video" component={CaptureFrame} />
            <Route path="/predict" component={Predict} />
            <Route path="/predict-shirt" component={FakePredict} />
            <Route path="/predict-neck-chain" component={FakePredict} />
            <Route path="/predict-sunglasses" component={FakePredict} />
            <Route path="/predict-briefcase" component={FakePredict} />
            <Route path="/predict-hawaiian-shirt" component={FakePredict} />
            <Route path="/predict-watch" component={FakePredict} />
            <Route path="/predict-panama-hat" component={FakePredict} />
            <Route path="/predict-blank" component={FakePredict} />
            <Route path="/upload" component={Upload} />
           </Router>    
    )
}

export default App;
