import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import cvInstance from "../../axios-cloud-vision-predict";
import ImagePreview from "../../components/ImagePreview/ImagePreview";
import TopImage from "./top-graphic.png";
import UploadButton from "./upload-button.png";
import "./Style.css";

const Upload = () => {
  const [labels, setlabels] = useState([]);
  const [dataUri, setDataUri] = useState(null);
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const bas64Img = reader.result;
        setDataUri(bas64Img);
        getCloudVisionData(bas64Img);
      };
      //reader.readAsArrayBuffer(file)
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const getCloudVisionData = (dataUri) => {
    let uri1 = dataUri.replace("data:image/jpeg;base64,", "");
    let uri2 = uri1.replace("data:image/png;base64,", "");
    const request = {
      payload: {
        image: {
          imageBytes: uri2,
        },
        models: [
          "ICN3994286050175352832",
          //"ICN5670555298394275840"
          //"ICN5823607316980695040"
          // "ICN6265505438230380544"
          /* "IOD8164054156143755264",
         "IOD757884568932974592",
         "ICN8253703936226099200" */
        ],
      },
      params: {
        //imageBytes: uri2,
        //scoreThreshold: "0.5"
      },
    };

    cvInstance
      .post("predict", request)
      .then((response) => {
        console.log("Products");
        console.log(response.data.result);
        if (response.data.result != null) {
          var stateCpy = [];
          stateCpy[0] = [{ description: response.data.result }];
          setlabels(stateCpy);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  return (
    <div className="App">
      {dataUri ? (
        <ImagePreview dataUri={dataUri} labels={labels} />
      ) : (
        // <ImagePreview dataUri={dataUri} labels={labels} />
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <p>
            <div>
              <img src={TopImage} className="img-style" />
            </div>
            <button className="upload-button">
              <img src={UploadButton} className="img-style" />
            </button>
          </p>
        </div>
      )}
    </div>
  );
};

export default Upload;
