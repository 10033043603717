
import React, { Component } from "react";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import cvInstance from "../../axios-cloud-vision";
import ImagePreview from "../../components/ImagePreview/ImagePreview"; // source code : ./src/demo/AppWithImagePreview/ImagePreview
import overlayImg from "../camera_overlay.png"

class ImageAnalyze extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { dataUri: null, labels: [] };
    this.onTakePhotoAnimationDone = this.onTakePhotoAnimationDone.bind(this);
  }

  onTakePhotoAnimationDone(dataUri) {
    console.log("takePhoto");
    this.getCloudVisionData(dataUri);
    this.setState({ dataUri: dataUri });
  }

  getCloudVisionData(dataUri) {
    let uri1 = dataUri.replace("data:image/jpeg;base64,", "");
    let uri2 = uri1.replace("data:image/png;base64,", "");
    const request = {
      requests: [
        {
          image: {
            content: uri2
             /* source: {
          gcsImageUri: uri2
        } */
          },
          features: [
              {
              type: "LABEL_DETECTION"

            }

          ]
        }
      ]
    };

    cvInstance
      .post("", request)
      .then(response => {
        console.log("Products");
        console.log(response);
        var stateCpy = [...this.state.labels];
        stateCpy[0] = response.data.responses[0].labelAnnotations;
        this.setState({ labels: stateCpy });
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        console.log("CV Request called");
         console.log(this.state);
      });
  }
  render() {
    return (
      <div className="App">
         {/*<div className="plus-icon">
      <img src={overlayImg} /> 
       </div>*/}
        {this.state.dataUri ? (
          <ImagePreview
            dataUri={this.state.dataUri}
            labels={this.state.labels}
          />
        ) : (
        <div>
          <div className="plus-icon">
          <img src={overlayImg} />
         </div>
          <Camera
            onTakePhotoAnimationDone={this.onTakePhotoAnimationDone}
            idealFacingMode={FACING_MODES.ENVIRONMENT}
            isImageMirror={false}
            />
          </div>
        )}
      </div>
    );
  }
}

export default ImageAnalyze;