import React from "react";
import Loader from "./loader.gif";
import GreenTick from "./greentick.gif";
import Sound from "react-sound";
import VuugleSound from "./vuugle-sound.mp3";
const LabelsDisplay = (labels) => {
  console.log("I am in Labels");
  console.log(labels["labels"]);
  //document.getElementById("portrait").style.display = "none";
  //document.getElementById("landscape").style.display = "block";
  var labelLists = ""; /*(
    <img
      src={Loader}
      alt=""
      width="150"
      height="100"
    />
  ); */
  if (labels["labels"][0] !== undefined) {
    console.log("Mapped Data");
    console.log(labels["labels"][0]);
    labelLists = labels["labels"][0].map((data) => {
      console.log("Inside Map");
      console.log(data);
      var url = null;
      var not_detected = null;
      /*if (data.description === "Eyewear") {
        url = "http://160.153.235.68/~adseen/product/ray-ban-sunglasses/";
      } else if (data.description === "Car") {
        url = "https://www.astonmartin.com/";
      }
      */

      /*  if (data.description === "Eyewear" || data.description === "Car" || data.description === "Man" 
          || data.description === "White Shirt" || data.description === "Sunglasses" || data.description === "Plane"
          || data.description === "Building" || data.description === "Helicopter" || data.description === "Boat"
          || data.description === "People" || data.description === "Suit" || data.description === "Watch"
          || data.description === "Road" || data.description === "Sea" || data.description === "Mobile Phone"
          || data.description === "Tablet" || data.description === "Hand" || data.description === "Tree"
          || data.description === "Flag" || data.description === "cctv" || data.description === "CCTV"
          || data.description === "Camera"|| data.description === "camera") {
        url = "http://www.adseen.tv/casino-royal-stamp145155/";
      } */
      if (data.description === "pen" || data.description === "Pen") {
        url = "https://www.vuugle.tv/pelican-pen/";
      } else if (
        data.description === "flowers" ||
        data.description === "flower" ||
        data.description === "Flowers" ||
        data.description === "Flower"
      ) {
        url = "https://www.vuugle.tv/flowers/";
      } else if (
        data.description === "Coffee Machine" ||
        data.description === "coffee machine" ||
        data.description === "Coffee machine"
      ) {
        url = "https://www.vuugle.tv/offee-machine/";
      } else if (
        data.description === "chairs" ||
        data.description === "chair" ||
        data.description === "Chairs" ||
        data.description === "Chair"
      ) {
        url = "https://www.amazon.com/s?k=office+chair&ref=nb_sb_noss_1";
      } else if (data.description === "phone" || data.description === "Phone") {
        url = "https://www.vuugle.tv/phone/";
      } else if (
        data.description === "Smartphone" ||
        data.description === "Mobile phone"
      ) {
        url = "https://www.vuugle.tv/mobile-phone/";
      } else if (
        data.description === "Trainers" ||
        data.description === "trainers"
      ) {
        url = "https://www.vuugle.tv/trainers/";
      } else if (data.description === "Shoes" || data.description === "shoes") {
        url = "https://www.vuugle.tv/shoes/"; // Product Shoes
      } else if (
        data.description === "glasses" ||
        data.description === "Glasses" ||
        data.description === "Sunglasses" ||
        data.description === "sunglasses"
      ) {
        url = "https://www.vuugle.tv/glasses/";
      } else if (
        data.description === "Baseball Cap" ||
        data.description === "baseball cap" ||
        data.description === "Baseball cap"
      ) {
        url = "https://www.vuugle.tv/baseball-cap/";
      } else if (data.description === "Watch" || data.description === "watch") {
        url = "https://www.vuugle.tv/watch/";
      } else if (data.description === "AirForce") {
        url = "http://www.adseen.tv/nike-air-max/";
      } else if (data.description === "danny_nike") {
        url = "http://www.adseen.tv/nike-air-max/";
      } else if (
        data.description === "bond_daniel" ||
        data.description === "bond_old_lady" ||
        data.description === "bond_beach" ||
        data.description === "bond_car"
      ) {
        url = "http://www.adseen.tv/casino-royal-stamp145155/";
      } else if (
        data.description === "JamesBond" ||
        data.description === "Starwars"
      ) {
        //|| data.description === "Starwars"
        url = "https://www.vuugle.tv/casino-royal-stamp145155/";
      } else if (data.description === "6_underground") {
        url = "https://www.vuugle.tv/6underground-stamp11234/";
      } else if (data.description === "Minority_report") {
        url = "https://www.vuugle.tv/minorityreport-stamp235662/";
      } else if (data.description === "Skyfall") {
        url = "https://www.vuugle.tv/skyfall-stamp92347/";
      } else if (data.description === "Football") {
        /*  else if(data.description === "Starwars"){
        url = "https://www.vuugle.tv/star-wars-empire-strikes-back435671/";
      } */
        url = "https://www.bet365.com/#/IP/";
      } else if (data.description === "GossipGirl") {
        url = "https://www.vuugle.tv/gossipgirl-stamp234587/";
      } else if (data.description === "khalid") {
        url = "https://www.vuugle.tv/dj-khaled-im-the-one/";
      } else if (data.description === "casino") {
        url = "https://www.vuugle.tv/housefull-casino-scene";
      } else if (data.description === "homebed") {
        url = "https://www.vuugle.tv/housefull-homebed-scene";
      } else if (data.description === "introcasino") {
        url = "https://www.vuugle.tv/housefull-introcasino-scene";
      } else if (data.description === "kitchen") {
        url = "https://www.vuugle.tv/housefull-kitchen-scene";
      } else if (data.description === "proposal") {
        url = "https://www.vuugle.tv/housefull-proposal-scene";
      } else if (data.description === "predict-shirt") {
        url = "https://www.vuugle.tv/product/western-shirt/";
      } else if (data.description === "predict-neck-chain") {
        url = "https://vuugle.tv/product/neck-chain";
      } else if (data.description === "predict-sunglasses") {
        url = "https://www.vuugle.tv/product/sunglasses/";
      } else if (data.description === "predict-briefcase") {
        url = "https://www.vuugle.tv/product/briefcase/";
      } else if (data.description === "predict-hawaiian-shirt") {
        url = "https://www.vuugle.tv/product/hawaiian-shirt/";
      } else if (data.description === "predict-watch") {
        url = "https://www.vuugle.tv/product/watch/";
      } else if (data.description === "predict-panama-hat") {
        url = "https://www.vuugle.tv/product/panama-hat/";
      } else if (data.description === "predict-blank") {
        url = "https://www.vuugle.tv/blank-page/";
      } // Following is for Eros New Models
      else if (data.description === "golmaal_3_opening_sequence") {
        url = "https://www.vuugle.tv/golmaal-3-opening-sequence/";
      } else if (data.description === "golmaal_3_campus_scene") {
        url = "https://www.vuugle.tv/golmaal-3-campus-scene/";
      } else if (data.description === "golmaal_3_beach_scene") {
        url = "https://www.vuugle.tv/golmaal-3-beach-scene/";
      } else if (data.description === "golmaal_3_bus_house_scene") {
        url = "https://www.vuugle.tv/golmaal-3-bus-and-house-scene/";
      } else if (data.description === "golmaal_3_car_scene") {
        url = "https://www.vuugle.tv/golmaal-3-car-scene/";
      } else if (data.description === "love_aaj_kal_carpark") {
        url = "https://www.vuugle.tv/love-aaj-kal-carpark/";
      } else if (data.description === "love_aaj_kal_lift") {
        url = "https://www.vuugle.tv/love-aaj-kal-lift/";
      } else if (data.description === "love_aaj_kal_club_scene") {
        url = "https://www.vuugle.tv/love-aaj-kal-club-scene/";
      } else if (data.description === "love_aaj_kal_bedroom_scene") {
        url = "https://www.vuugle.tv/love-aaj-kal-bedroom-scene/";
      } else if (data.description === "love_aaj_kal_wedding_scene") {
        url = "https://www.vuugle.tv/love-aaj-kal-wedding-scene/";
      } else if (data.description === "love_aaj_kal_house_scene") {
        url = "https://www.vuugle.tv/love-aaj-kal-house-scene/";
      } else if (data.description === "love_aaj_kal_travel_scene") {
        url = "https://www.vuugle.tv/love-aaj-kal-travel-scene/";
      } else if (data.description === "rockstar_running_scene") {
        url = "https://www.vuugle.tv/rockstar-running-scene/";
      } else if (data.description === "rockstar_bus_scene") {
        url = "https://www.vuugle.tv/rockstar-bus-scene/";
      } else if (data.description === "rockstar_concert_1") {
        url = "https://www.vuugle.tv/rockstar-concert-1/";
      } else if (data.description === "rockstar_concert_2") {
        url = "https://www.vuugle.tv/rockstar-concert-2/";
      } else if (data.description === "rockstar_concert_3") {
        url = "https://www.vuugle.tv/rockstar-concert-3/";
      } else if (data.description === "rockstar_concert_4") {
        url = "https://www.vuugle.tv/rockstar-concert-4/";
      } else if (data.description === "rockstar_concert_5") {
        url = "https://www.vuugle.tv/rockstar-concert-5/";
      } else if (data.description === "rockstar_campus_scene") {
        url = "https://www.vuugle.tv/rockstar-campus-scene/";
      } else if (data.description === "rockstar_campus_scene_2") {
        url = "https://www.vuugle.tv/rockstar-campus-scene-2/";
      } else if (data.description === "rockstar_music_scene") {
        url = "https://www.vuugle.tv/rockstar-music-scene/";
      } else if (data.description === "rockstar_hotel_scene") {
        url = "https://www.vuugle.tv/rockstar-hotel-scene/";
      } else if (data.description === "rockstar_canteen_scene_1") {
        url = "https://www.vuugle.tv/rockstar-canteen-scene-1/";
      } else if (data.description === "rockstar_canteen_scene_2") {
        url = "https://www.vuugle.tv/rockstar-canteen-scene-2/";
      } else if (data.description === "rockstar_canteen_scene_4") {
        url = "https://www.vuugle.tv/rockstar-canteen-scene-4/";
      } else if (data.description === "rockstar_stage_scene") {
        url = "https://www.vuugle.tv/rockstar-stage-scene/";
      } else if (data.description === "rockstar_home_scene") {
        url = "https://www.vuugle.tv/rockstar-home-scene/";
      } else if (data.description === "twmr_wedding_scene") {
        url = "https://www.vuugle.tv/tanu-weds-manu-returns-wedding-scene/";
      } else if (data.description === "twmr_outside_hospital") {
        url = "https://www.vuugle.tv/tanu-weds-manu-returns-outside-hospital/";
      } else if (data.description === "twmr_doctors_office_scene1") {
        url =
          "https://www.vuugle.tv/tanu-weds-manu-returns-doctors-office-scene-1/";
      } else if (data.description === "twmr_doctors_office_scene2") {
        url =
          "https://www.vuugle.tv/tanu-weds-manu-returns-doctors-office-scene-2/";
      } else if (data.description === "twmr_home_kitchen") {
        url = "https://www.vuugle.tv/tanu-weds-manu-returns-home-kitchen/";
      } else if (data.description === "twmr_sitting_room") {
        url = "https://www.vuugle.tv/tanu-weds-manu-returns-sitting-room/";
      } else if (data.description === "twmr_super_market") {
        url = "https://www.vuugle.tv/tanu-weds-manu-returns-supermarket/";
      } else if (data.description === "twmr_bus_scene") {
        url = "https://www.vuugle.tv/tanu-weds-manu-returns-bus-scene/";
      } else if (data.description === "twmr_airplane") {
        url = "https://www.vuugle.tv/tanu-weds-manu-returns-airplane/";
      } else if (data.description === "twmr_party_scene") {
        url = "https://www.vuugle.tv/tanu-weds-manu-returns-party-scene/";
      }

      if (url != null) {
        return (
          <div>
            <div>
              <Sound
                url={VuugleSound}
                playStatus={Sound.status.PLAYING}
                autoLoad="true"
                onFinishedPlaying={setTimeout(() => {
                  window.location.replace(url);
                  //window.open(url);
                  //console.log("URL Detection");
                }, 3000)}
              />
              {/* <img
      src={GreenTick}
      alt=""
    /> */}
            </div>
            {/* <div>Detected product taking you to vendor</div> */}
          </div>
        );
      }
      // return <li>{data.description}</li>;
    });
  } else {
    setTimeout(() => {
      //console.log("No Detection");
      window.location = "https://www.vuugle.tv/nodetection";
    }, 20000);
  }

  return <div>{labelLists}</div>;
};

export default LabelsDisplay;
