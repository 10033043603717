
import React, { Component } from "react";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import cvInstance from "../../axios-cloud-vision-predict";
import ImagePreview from "../../components/ImagePreview/ImagePreview"; // source code : ./src/demo/AppWithImagePreview/ImagePreview
import overlayImg from "../camera_overlay.png"
import "./Style.css";
import PortraitIcon from "./Portrait_icon.PNG";
import PortraitGif from "./Portrait_gif.gif";


class FakePredict extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { dataUri: null, labels: [], portrait:true };
    this.onTakePhotoAnimationDone = this.onTakePhotoAnimationDone.bind(this);
  }

  componentDidMount(){
    // let label = this.props.location.pathname.replace("/","");
    // alert(label);
  }

  onTakePhotoAnimationDone(dataUri) {
    let label = this.props.location.pathname.replace(/^\/|\/$/g, '').trim();
    this.getFakeData(label);
    this.setState({ dataUri: dataUri });
  }

  getFakeData(label) {
  	this.setState({...this.state,portrait:false});
    var stateCpy = [...this.state.labels];
        stateCpy[0] = [{"description":label}];  
        this.setState({labels:stateCpy}); 
  }
  render() {
    return (
      <div>

      <div id="portrait"><img src={PortraitGif} /></div>
    
      <div className="App" id="landscape">

        {this.state.dataUri ? (
          <ImagePreview
            dataUri={this.state.dataUri}
            labels={this.state.labels}
          />
        ) : (
          <div>
                <div className="plus-icon">
        			<img src={overlayImg} />
       			</div>
	          <Camera
	            onTakePhotoAnimationDone={this.onTakePhotoAnimationDone}
	            idealFacingMode={FACING_MODES.ENVIRONMENT}
	            isImageMirror={false}
	          />
          </div>
        )}
      </div>
      </div>
    );
  }
}

export default FakePredict;